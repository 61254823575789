// Fonts
// @import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import "@fontsource/yanone-kaffeesatz";

// Variables
@import 'variables';

// Bootstrap
$enable-negative-margins: true;
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';
@font-face {
    font-display: swap;
    font-family: 'bootstrap-icons';
    src: url('~bootstrap-icons/font/fonts/bootstrap-icons.woff') format('woff'),
    url('~bootstrap-icons/font/fonts/bootstrap-icons.woff2') format('woff2');
}
@import '~fotorama/fotorama.css';

/** bootstap 4 -> 5 **/
:root {
    --bs-body-bg: white;
}

.col-form-label {
    font-size: inherit;
    line-height: 1.6;
    margin-bottom: 0;
    padding-bottom: calc(0.375rem + 1px);
    padding-top: calc(0.375rem + 1px);
}
/** END **/
